// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const adminResetSearchProducts = () => ({
  type: ActionTypes.ADMIN_SEARCH_PRODUCTS_RESET
});

export const adminResetSearchKeywords = () => ({
  type: ActionTypes.ADMIN_SEARCH_KEYWORDS_RESET
});

export const adminResetProductDetail = () => ({
  type: ActionTypes.ADMIN_PRODUCT_DETAIL_RESET
});

export const adminResetCategorySearch = () => ({
  type: ActionTypes.ADMIN_CATEGORY_SEARCH_RESET
});

export const adminGetCommonProducts = (reqData, category) => ({
  type: ActionTypes.ADMIN_GET_COMMON_PRODUCTS_START,
  payload: reqData,
  category: category
});

export const adminGetCommonProductsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_COMMON_PRODUCTS_SUCCESS,
    payload: data,
  };
};
export const adminGetCommonProductsFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_COMMON_PRODUCTS_FAILURE,
  payload: error,
});

export const adminGetStorePrivateProducts = (reqData, isPrivate) => ({
  type: ActionTypes.ADMIN_GET_STORE_PRIVATE_PRODUCTS_START,
  shopId: reqData,
  private: isPrivate
});

export const adminGetStorePrivateProductsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_STORE_PRIVATE_PRODUCTS_SUCCESS,
    payload: data,
  };
};
export const adminGetStorePrivateProductsFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_STORE_PRIVATE_PRODUCTS_FAILURE,
  payload: error,
});

export const adminGetStorePrivateCategories = (reqData, isPrivate) => ({
  type: ActionTypes.ADMIN_GET_STORE_PRIVATE_CATEGORIES_START,
  shopId: reqData,
  private: isPrivate
});

export const adminGetStorePrivateCategoriesSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_STORE_PRIVATE_CATEGORIES_SUCCESS,
    payload: data,
  };
};
export const adminGetStorePrivateCategoriesFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_STORE_PRIVATE_CATEGORIES_FAILURE,
  payload: error,
});

export const adminGetProductInfo = (reqData) => ({
  type: ActionTypes.ADMIN_GET_PRODUCT_INFO_START,
  payload: reqData,
});

export const adminGetProductInfoSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_PRODUCT_INFO_SUCCESS,
    payload: data,
  };
};
export const adminGetProductInfoFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_PRODUCT_INFO_FAILURE,
  payload: error,
});

export const adminGetProductAndShopsInfo = (reqData) => ({
  type: ActionTypes.ADMIN_GET_PRODUCT_SHOPS_INFO_START,
  payload: reqData,
});

export const adminGetProductAndShopsInfoSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_PRODUCT_SHOPS_INFO_SUCCESS,
    payload: data,
  };
};
export const adminGetProductAndShopsInfoFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_PRODUCT_SHOPS_INFO_FAILURE,
  payload: error,
});

export const adminUpdateProductStatus = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_PRODUCT_STATUS_START,
  payload: reqData,
});

export const adminUpdateProductStatusSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_PRODUCT_STATUS_SUCCESS,
    payload: data,
  };
};
export const adminUpdateProductStatusFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_PRODUCT_STATUS_FAILURE,
  payload: error,
});

export const adminUpdateProductInfo = (req) => ({
  type: ActionTypes.ADMIN_UPDATE_PRODUCT_INFO_START,
  payload: req,
});

export const adminUpdateProductInfoSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_PRODUCT_INFO_SUCCESS,
    payload: data,
  };
};
export const adminUpdateProductInfoFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_PRODUCT_INFO_FAILURE,
  payload: error,
});

export const adminUpdateProductCategory = (req) => ({
  type: ActionTypes.ADMIN_UPDATE_PRODUCT_CATEGORY_START,
  payload: req,
});

export const adminUpdateProductCategorySuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_PRODUCT_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const adminUpdateProductCategoryFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_PRODUCT_CATEGORY_FAILURE,
  payload: error,
});

export const adminSearchCommonCategory = (name) => ({
  type: ActionTypes.ADMIN_SEARCH_COMMON_CATEGORY_START,
  name: name,
});

export const adminSearchCommonCategorySuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_COMMON_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const adminSearchCommonCategoryFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_COMMON_CATEGORY_FAILURE,
  payload: error,
});

export const adminGetCommonProductCategories = (reqData) => ({
  type: ActionTypes.ADMIN_GET_COMMON_CATEGORIES_START,
  payload: reqData,
});

export const adminGetCommonProductCategoriesSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_COMMON_CATEGORIES_SUCCESS,
    payload: data,
  };
};
export const adminGetCommonProductCategoriesFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_COMMON_CATEGORIES_FAILURE,
  payload: error,
});

export const adminGetCommonProductMainCategories = (reqData) => ({
  type: ActionTypes.ADMIN_GET_COMMON_MAIN_CATEGORIES_START,
  payload: reqData,
});

export const adminGetCommonProductMainCategoriesSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_COMMON_MAIN_CATEGORIES_SUCCESS,
    payload: data,
  };
};
export const adminGetCommonProductMainCategoriesFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_COMMON_MAIN_CATEGORIES_FAILURE,
  payload: error,
});

export const adminGetCommonProductColors = (reqData) => ({
  type: ActionTypes.ADMIN_GET_COMMON_COLORS_START,
  payload: reqData,
});

export const adminGetCommonProductColorsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_COMMON_COLORS_SUCCESS,
    payload: data,
  };
};
export const adminGetCommonProductColorsFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_COMMON_COLORS_FAILURE,
  payload: error,
});


export const adminUpdateStatusPrivateProduct = (req) => ({
  type: ActionTypes.ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_START,
  payload: req,
});

export const adminUpdateStatusPrivateProductSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const adminUpdateStatusPrivateProductFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_FAILURE,
  payload: error,
});

export const adminSearchProducts = (name, isShop, shopId, status, datebefore, sort, category, city, offset) => ({
  type: ActionTypes.ADMIN_SEARCH_PRODUCTS_START,
  name: name,
  isShop: isShop,
  shopId: shopId,
  status: status,
  start: datebefore,
  sort: sort,
  category: category,
  city: city,
  offset: offset,
});

export const adminSearchProductsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_PRODUCTS_SUCCESS,
    payload: data,
  };
};

export const adminSearchProductsFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_PRODUCTS_FAILURE,
  payload: error,
});

export const adminSearchProductKeywords = (name, shopId, isShop,) => ({
  type: ActionTypes.ADMIN_SEARCH_PRODUCTS_KEYWORDS_START,
  name: name,
  shopId: shopId,
  isShop: isShop,
});

export const adminSearchProductKeywordsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_PRODUCTS_KEYWORDS_SUCCESS,
    payload: data,
  };
};

export const adminSearchProductKeywordsFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_PRODUCTS_KEYWORDS_FAILURE,
  payload: error,
});

export const adminAddPrivateProductQuick = (reqData, formData) => ({
  type: ActionTypes.ADMIN_ADD_PRIVATE_PRODUCT_QUICK_START,
  payload: reqData,
  formData: formData
});

export const adminAddPrivateProductQuickSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_PRIVATE_PRODUCT_QUICK_SUCCESS,
    payload: data,
  };
};
export const adminAddPrivateProductQuickFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_PRIVATE_PRODUCT_QUICK_FAILURE,
  payload: error,
});

export const adminUpdateProductVideo = (prod_id, formData) => ({
  type: ActionTypes.ADMIN_UPDATE_PRODUCT_VIDEO_START,
  payload: prod_id,
  formData: formData
});

export const adminUpdateProductVideoSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_PRODUCT_VIDEO_SUCCESS,
    payload: data,
  };
};

export const adminUpdateProductVideoFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_PRODUCT_VIDEO_FAILURE,
  payload: error,
});

export const adminAddCommonProduct = (reqData) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_PRODUCT_START,
  payload: reqData,
});

export const adminAddCommonProductSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_COMMON_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const adminAddCommonProductFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_PRODUCT_FAILURE,
  payload: error,
});

export const adminUpdateCommonProduct = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_COMMON_PRODUCT_START,
  payload: reqData,
});

export const adminUpdateCommonProductSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_COMMON_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const adminUpdateCommonProductFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_COMMON_PRODUCT_FAILURE,
  payload: error,
});


export const adminAddCommonProductImage = (reqData, formData) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_PRODUCT_IMAGE_START,
  payload: reqData,
  formData: formData
});

export const adminAddCommonProductImageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_COMMON_PRODUCT_IMAGE_SUCCESS,
    payload: data,
  };
};
export const adminAddCommonProductImageFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_PRODUCT_IMAGE_FAILURE,
  payload: error,
});

export const adminRemoveCommonProductImage = (reqData, image_id) => ({
  type: ActionTypes.ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_START,
  payload: {prod_id: reqData, image_id: image_id},
});

export const adminRemoveCommonProductImageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_SUCCESS,
    payload: data,
  };
};
export const adminRemoveCommonProductImageFailure = (error) => ({
  type: ActionTypes.ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_FAILURE,
  payload: error,
});

export const adminAddCommonCategory = (reqData) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_CATEGORY_START,
  payload: reqData,
});

export const adminAddCommonCategorySuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_COMMON_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const adminAddCommonCategoryFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_CATEGORY_FAILURE,
  payload: error,
});

export const adminUpdateCommonCategory = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_START,
  payload: reqData,
});

export const adminUpdateCommonCategorySuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const adminUpdateCommonCategoryFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_FAILURE,
  payload: error,
});

export const adminUpdateCommonCategorySetting = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_START,
  payload: reqData,
});

export const adminUpdateCommonCategorySettingSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const adminUpdateCommonCategorySettingFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_FAILURE,
  payload: error,
});

export const adminAddCommonCategoryImage = (reqData, formData) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_START,
  payload: reqData,
  formData: formData
});

export const adminAddCommonCategoryImageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_SUCCESS,
    payload: data,
  };
};
export const adminAddCommonCategoryImageFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_FAILURE,
  payload: error,
});

export const adminRemoveCommonCategoryImage = (reqData, image_id) => ({
  type: ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_START,
  payload: {categ_id: reqData, image_id: image_id},
});

export const adminRemoveCommonCategoryImageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_SUCCESS,
    payload: data,
  };
};
export const adminRemoveCommonCategoryImageFailure = (error) => ({
  type: ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_FAILURE,
  payload: error,
});
