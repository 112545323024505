import React, {useState} from 'react';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {IconButton} from "@mui/material";
import { H2 } from "components/CustomTypography";
import { Box, Button } from "@mui/material";
import ButtonComponent from "../Button";

export default function Story(props) {

  const {
    avatar,
    userLink = '',
      linkTitle= '',
    name,
    area,
      favId,
    duration,
    closeButton,
    toggleMute,
      isMuted,
      setFav,
    onCloseButtonClick,
    children,
    ...rest
  } = props;

    const [isFav, setIsFav] = useState(false);

    const handleSetFav = () => {
        if (!isFav) {
            setIsFav(true);
            setFav(favId);
        }
    }

  return (
      <div className="swiper-slide" data-duration={duration} {...rest}>

              <a href={userLink} className="stories-slider-user">
                  {avatar && <div className="stories-slider-user-avatar">{avatar}</div>}
                  <div>
                      {name && <H2 className="stories-slider-user-name" ellipsis>{name}</H2>}
                      {area && <div className="stories-slider-user-area">{area}</div>}
                      {linkTitle && <ButtonComponent className="stories-slider-user-link" sx={{ padding: "2px 2px", minWidth: "auto", backgroundColor: "white" }}
                          type="primary"
                          variant="outlined"
                          name={linkTitle}
                          size="small">
                      </ButtonComponent>}
                  </div>
              </a>
              <div className="stories-slider-actions">

                  <IconButton color={ isFav ? "primary" : "error"} onClick={handleSetFav}>
                      {isFav ? <FavoriteIcon fontSize="large"/> : <FavoriteBorderIcon fontSize="large"/>}
                  </IconButton>

                  <IconButton sx={{color: "grey.100"}} onClick={toggleMute}>
                      {isMuted ? <VolumeOffIcon fontSize="large"/> : <VolumeUpIcon fontSize="large"/>}
                  </IconButton>

                  {closeButton && (
                      <button
                          className="stories-slider-close-button"
                          onClick={onCloseButtonClick}
                      />
                  )}
              </div>
          <div className="stories-slider-content">{children}</div>
      </div>
  );
}
