import * as React from "react";

import "./style.scss";

import {useEffect} from "react";
import {Box, Container, Divider, Typography} from "@mui/material";
import CustomFlexBox from "components/CustomFlexBox";
import CategoryItem from "./CategoryItems";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "../../../../../actions";

const ShopProductCategorySlider = (props) => {

  const { usergetProductCategories, isDirect, handleSelectCategoryLink, handleSelectCategorySet, bootupInfo, commonCategoryList, selectedCategory } = props;

  useEffect(() => {
    usergetProductCategories(bootupInfo.appState.subdomain_shopId,
        true, bootupInfo.appState.isWebsite);
  }, []);

  /*
  const handleSelectCategory = (id) => {
    props.history.push(`/product/category/${id}`);
  };

   */

  if (!commonCategoryList) {
    return null;
  }


  const { categories } = commonCategoryList;

  if (!categories.length) {
    return <></>;
  }


  const breakpoints = {
    300: {
      slidesPerView: 3.5,
      spaceBetween: 0,
    },
    340: {
      slidesPerView: 3.5,
      spaceBetween: 0,
    },
    380: {
      slidesPerView: 4.5,
      spaceBetween: 0,
    },
    420: {
      slidesPerView: 4.5,
      spaceBetween: 0,
    },
    480: {
      slidesPerView: 4.5,
      spaceBetween: 0,
    },
    640: {
      slidesPerView: 4.5,
      spaceBetween: 0,
    },
    768: {
      slidesPerView: 4.5,
      spaceBetween: 0,
    },
    1024: {
      slidesPerView: 5.5,
      spaceBetween: 0,
    },
  };

  return (

      <Box>
        {categories &&
            <>
              <CustomFlexBox bgcolor="header" justifyContent="space-between" alignItems="center" p={1} />
              <swiper-container slides-per-view="5.5" space-between="0" free-mode="true" breakpoints={JSON.stringify(breakpoints)}>
                {categories.length > 0 && categories.map((category, index) => (
                    <swiper-slide key={index}>
                      <Box sx={{ width: '100%', alignItems: 'center'}}>
                        <CategoryItem key={`${index + 1}`} isDirect={isDirect} isSelected={selectedCategory === category._id} isSmall={true} category={category}
                                      handleSelectCategoryLink={handleSelectCategorySet}
                                      handleSelectCategorySet={handleSelectCategorySet}/>
                      </Box>
                    </swiper-slide>
                ))}
              </swiper-container>
            </>
        }

      </Box>
);
};

ShopProductCategorySlider.propTypes = {
  usergetProductCategories: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  categoryList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const _shopProductCategorySlider = connect(
    ({ userproducts, bootupInfo }) => ({
      bootupInfo,
      commonCategoryList: userproducts.categoryList,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopProductCategorySlider);

export default _shopProductCategorySlider;
