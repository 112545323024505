/* eslint-disable no-underscore-dangle */
import React from "react";
import {
  Box
} from "@mui/material";
import PropTypes from "prop-types";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import {getImage, } from "helpers/utils";
import {H2, } from "components/CustomTypography";

const CategoryItem = (props) => {
     const { isSelected, isDirect, category, handleSelectCategoryLink, handleSelectCategorySet } = props;

    return (
        <Box backgroundColor={ isSelected ? "primary.main" : "grey.100" } mr={1} p={1} flexWrap="wrap" onClick={isDirect ? () => handleSelectCategoryLink(category._id) : () => handleSelectCategorySet(category._id)}>
            <H2 textTransform="capitalize" color={ isSelected ? "grey.100" : "primary.main"} fontSize={14} textAlign="center" title={category.name} >
                {category.name}
            </H2>
    </Box>);

};

CategoryItem.propTypes = {
  color: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CategoryItem;
