/* eslint-disable no-underscore-dangle */
import React from "react";
import {
  Box
} from "@mui/material";
import PropTypes from "prop-types";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import {getImage, } from "helpers/utils";
import {H2, } from "components/CustomTypography";

const ColorItem = (props) => {
     const { isSelected, isDirect, color, handleSelectColorLink, handleSelectColorSet } = props;

    return (<Box backgroundColor={ isSelected ? "primary.main" : "grey.100" } p={2} display="flex" flexWrap="wrap" onClick={isDirect ? () => handleSelectColorLink(color) : () => handleSelectColorSet(color)}>
        <Box textAlign="center" style={{ width: 15, height: 15, borderRadius: "50%", marginRight: 1, backgroundColor: color}}></Box>&nbsp;
        <Box textAlign="center" title={color} ellipsis>
            <H2 textTransform="capitalize" color={ isSelected ? "grey.100" : "grey.900"} fontSize={12} textAlign="center" title={color} ellipsis>
                {color}
            </H2>
        </Box>
    </Box>);

};

ColorItem.propTypes = {
  color: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ColorItem;
