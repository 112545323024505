/* eslint-disable import/prefer-default-export */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
// @flow

// AdminApp routes

import AdminMainHome from "components/pages/admin/home/AdminMainHome";
import AdminShops from "components/pages/admin/shops/AdminShops";
import AdminMainProductSearch from "components/pages/admin/products/AdminMainProductSearch";
import AdminMainShopSearch from "components/pages/admin/shops/AdminMainShopSearch";
import AdminMainOrderSearch from "components/pages/admin/orders/AdminMainOrderSearch";

import AdminShopProductList from "components/pages/admin/products/AdminShopProductList";
//import AdminShopProductDetail from "components/pages/admin/products/AdminShopProductDetail";
import AdminProductAdd from "components/pages/admin/products/AdminProductAdd";
import AdminCommonCategories from "components/pages/admin/products/AdminCommonCategories";

import AdminShopOrderList from "components/pages/admin/orders/AdminShopOrderList";
import AdminShopOverall from "components/pages/admin/shops/AdminShopOverall";

import AdminSales from "components/pages/admin/sales/AdminSales";
import AdminOffersMain from "components/pages/admin/offers-coupons/AdminOffersMain";

import AdminSubscriptions from "components/pages/admin/subscriptions/AdminSubscriptions";


export const AdminAppRoutes = [
  { path: "/", component: AdminMainHome, exact: true },
  //{ path: "/shops", component: AdminMainShopSearch, exact: true },
  { path: "/shops", component: AdminShops, exact: true },
  { path: "/shops/ssearch", component: AdminMainShopSearch, exact: true },
  { path: "/shops/ssearch/:query", component: AdminMainShopSearch, exact: true },

  { path: "/sales", component: AdminSales, exact: true },

  { path: "/products", component: AdminMainProductSearch, exact: true },
  { path: "/products/psearch", component: AdminMainProductSearch, exact: true },
  { path: "/products/psearch/:query", component: AdminMainProductSearch, exact: true },
  { path: "/products/add", component: AdminProductAdd, exact: true },
  { path: "/products/categories", component: AdminCommonCategories, exact: true },
  //{ path: "/products/:prodId", component: AdminShopProductDetail, exact: true },

  { path: "/products/shop/:shopId", component: AdminShopProductList, exact: true },
  { path: "/products/shop/:shopId/psearch", component: AdminShopProductList, exact: true },
  { path: "/products/shop/:shopId/psearch/:query", component: AdminShopProductList, exact: true },

  { path: "/orders", component: AdminMainOrderSearch, exact: true },
  { path: "/orders/osearch", component: AdminMainOrderSearch, exact: true },
  { path: "/orders/osearch/:query", component: AdminMainOrderSearch, exact: true },
  { path: "/orders/shop/:shopId", component: AdminShopOrderList, exact: true },
  { path: "/orders/shop/:shopId/osearch", component: AdminShopOrderList, exact: true },
  { path: "/orders/shop/:shopId/osearch/:query", component: AdminShopOrderList, exact: true },

  { path: "/shops/shop/:shopId", component: AdminShopOverall, exact: true },
  { path: "/shops/shop/:shopId/psearch", component: AdminShopProductList, exact: true },
  { path: "/shops/shop/:shopId/psearch/:query", component: AdminShopProductList, exact: true },
  { path: "/shops/shop/:shopId/osearch", component: AdminShopOrderList, exact: true },
  { path: "/shops/shop/:shopId/osearch/:query", component: AdminShopOrderList, exact: true },

  { path: "/offers", component: AdminOffersMain, exact: true },

  { path: "/subscriptions", component: AdminSubscriptions, exact: true },


//  { path: "/search", component: SearchLandingPage, exact: true },

];
