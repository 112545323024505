//@flow
import moment from "moment-timezone";
import * as _ from "lodash";
import React from "react";
import { call } from "redux-saga/effects";
import {Box} from "@mui/material";
// constants
import {
  DATE_FORMAT,
  TimeSlots,
  SalesLeadStatus,
  SalesLeadFilter,
  adminSalesFutureFilter,
  adminProductStatus,
  adminProductSort,
  adminShopFilter,
  adminShopSort,
  userProductSort,
  adminOfferFilter
} from "config/constants";
import API from "../api";
import * as Actions from "../actions";
import querystring from "querystring";
import ButtonComponent from "../components/Button";
export const regEx = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[0-9]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,}).{8,}$/,
};

export const currencyFormatter = (
  value: number,
  format: string = "en-US",
  currency: string = "USD"
) => {
  const formatter = new Intl.NumberFormat(format, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  });
  return _.isFinite(value) ? formatter.format(value) : "";
};

export const getLocalDate = (
  dateValue: string,
  dateFormat: string = DATE_FORMAT
) => {
  const formattedDate = moment
    .utc(dateValue)
    .tz("Asia/Kolkata")
    .format(dateFormat);
  return formattedDate;
};

export const getPlatform = () => {

  var platform = "unknown";

  if (window.cordova) {
    if (window.cordova.platformId === "android") {
      platform = "android";
    } else if (window.cordova.platformId === "ios") {
      platform = "ios";
    }
  } else {
    platform = "web";
  }
  return platform;
}

export const isMobileApp = () => {
  if (window.cordova) {
    return true;
  }
  return false;
}

export const isAndroidApp = () => {
  if (window.cordova) {
    if (window.cordova.platformId === "android") {
      return true;
    }
  }
  return false;
}

export const isIOSApp = () => {
  if (window.cordova) {
    if (window.cordova.platformId === "ios") {
      return true;
    }
  }
  return false;
}

export let CitiesList = [
  { id: "63fbf62e8c5afe238607a106", name: "Ahmedabad", code: "amd", lat: "23.05", lng: "72.53" }
];

export const calculateDistance = (lat1, lon1, lat2, lon2, unit) => {

  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0;
  }
  else {
    var radlat1 = Math.PI * lat1/180;
    var radlat2 = Math.PI * lat2/180;
    var theta = lon1-lon2;
    var radtheta = Math.PI * theta/180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180/Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit==="K") { dist = dist * 1.609344 }
    if (unit==="N") { dist = dist * 0.8684 }
    if (unit==="M") { dist = getDistance(dist * 1.609344 * 1000); }
    return dist;
  }
}

export const getDistanceLocations = (location1, location2) => {
  return calculateDistance(location1.coordinates[1], location1.coordinates[0], location2.coordinates[1], location2.coordinates[0], "M");
}

// Local storage keeps as string
export const isCitySaved = () => {
  var isCitySaved = localStorage.getItem("isCitySaved" );
  return isCitySaved === "true";
};

export const isCurrentLocationSaved = () => {
  var isCurrentLocationSaved = localStorage.getItem("isLocationSaved");
  return isCurrentLocationSaved === "true";
};


export const getGeoLocationDue = () => {

  if (process.env.REACT_APP_CHECK_GEO_ALLOWED === "false") {
    return false;
  }

  if (isCurrentLocationSaved()) {
    var lastlocationcheck = parseInt(localStorage.getItem("lastlocationcheck"));
    if (Date.now() - lastlocationcheck > 30000) {
      return true;
    }
    return false;
  } else {
    return true
  }
};

export const getGeoLocation = () => {

  var city;

  if (isCurrentLocationSaved()) {
    var location_lat = localStorage.getItem("location_lat");
    var location_lng = localStorage.getItem("location_lng");
    return {
      lat: location_lat,
      lng: location_lng
    };
  } else if (isOnLoadCitySaved()) {
    city = getCity();
  } else if (isCitySaved()) {
    // get the city and lat/lng
    city = getCity();
  } else {
    // sets the city and lat/lng
    setCity();
    city = getCity();
  }

  return {
    lat: city.lat,
    lng: city.lng
  }

};

export const updateLastCheckTime = () => {
  localStorage.setItem("lastlocationcheck", Date.now());
}

export const checkIfCityChanging = (nextCity) => {
  if (nextCity.id !== localStorage.getItem("cityid")) {
    return true;
  }
  return false;
}

export const updateUserLocation = (location, currentCity) => {

  localStorage.setItem("isLocationSaved", true);

  // precise location should reset city
  localStorage.setItem("cityid", currentCity.id);
  localStorage.setItem("cityname", "Current Location");

  // update precise location
  updateLastCheckTime();

  localStorage.setItem("location_lat", location.latitude);
  localStorage.setItem("location_lng", location.longitude);

};

export const isOnLoadCitySaved = () => {
  var isOnLoadCitySaved = localStorage.getItem("isOnLoadCitySaved" );
  return isOnLoadCitySaved === "true";
};

export const setLoadCitySaved = () => {
  localStorage.setItem("isOnLoadCitySaved", true);
};

export const getOnLoadCityCode = () => {
  return localStorage.getItem("onLoadCityCode");
};

export const getOnLoadSavedCity = () => {
  var onLoadCityCode = localStorage.getItem("onLoadCityCode");
  return CitiesList.find((c) => c.code === onLoadCityCode);
};

export const setLoadCity = (onLoadCityCode) => {

  localStorage.setItem("onLoadCityCode", onLoadCityCode);

  // city code loaded but not saved - waiting to load area
  localStorage.setItem("isOnLoadCitySaved", false);
};


export const getSelectedCity = (location) => {
  return CitiesList.find((c) => calculateDistance(c.lat, c.lng, location.lat, location.lng, "K") < 30);
};

export const setCity = (id, cities) => {

  var city;
  var cityid;

  // if ID is provided otherwise null
  id = id ? id : null;

  // if cities is provided otherwise pick first from static list
  cities = (cities && cities.length > 0) ? cities : CitiesList;

  if (id) {
    city = cities.find((c) => c.id === id);
  } else {
    city = cities[0];
  }
  cityid = (city && city.id ? city.id : id);

  localStorage.setItem("isCitySaved", true);
  //localStorage.setItem("isLocationSaved", false);
  localStorage.setItem("cityid", cityid);
  localStorage.setItem("cityname", city.name);
  localStorage.setItem("location_lat", city.lat);
  localStorage.setItem("location_lng", city.lng);
};

export const getCity = () => {

  var cityid = localStorage.getItem("cityid");
  var cityname = localStorage.getItem("cityname");
  var location_lat = localStorage.getItem("location_lat");
  var location_lng = localStorage.getItem("location_lng");

  var city = {id: cityid, name: cityname, lat: location_lat, lng: location_lng};

  return (isCitySaved() ? city : CitiesList[0]);

};

export const getCityListStatic = () => {
  return CitiesList;
};

export const updateCityList = (cities) => {
  CitiesList = (cities && cities.length > 0) ? cities : CitiesList;
};

export const isLocationInSupportedCity = (location) => {

  return CitiesList.find((c) => calculateDistance(c.lat, c.lng, location.latitude, location.longitude, "K") < 30);

};

export const getIsMute = () => {
  var isMute = localStorage.getItem("isMute");
  return isMute === "true";
};

export const setIsMute = (mute) => {
  localStorage.setItem("isMute", mute);
};

export const getPrice = (product, currency) => {
  return `${product.is_price_visible ? product.is_price_onunlock ? product.fav ?  currency + parseFloat(product.price).toFixed(0) : "'Like' to see price" : currency + parseFloat(product.price).toFixed(0) : "Call for price"}`;
};

export const isAddCartAllowed = (product) => {
  if (product.is_price_visible) {
    if (product.is_price_onunlock && product.fav) {
      return true;
    } else if (product.is_price_onunlock) {
       return false;
    }
    return true;
  }
  return false;
};


export const getPriceCurrency = (price, currency) => {
  return `${price && currency + parseFloat(price).toFixed(2)}`;
};

export const getDistance = (distance) => {
  return `${distance ?  "(" + (parseFloat(distance)/1000).toFixed(2) + "Kms)" : ""}`;
};

export const getDistanceKms = (distance) => {
  return `${distance ?  "(" + (parseFloat(distance)).toFixed(2) + "Kms)" : ""}`;
};

export const getLogo = (logo) => {
  return `${logo && logo[0] ? logo[0].cdn.secure_url : "/assets/images/no_medium.jpg"}`;
};

export const getImage = (images, num) => {
  var x = num ? num : 0;
  return `${images && images[x] ? images[x].cdn.secure_url : "/assets/images/no_medium.jpg"}`;
};

export const getImageOrLogo = (images, logo) => {
  return `${images && images[0] ? images[0].cdn.secure_url : (logo && logo[0] ? logo[0].cdn.secure_url : "/assets/images/no_medium.jpg")}`;
};

export const getImageFromURL = (images) => {
  return `${images ? images.cdn.secure_url : "/assets/images/no_medium.jpg"}`;
};

export const openGoogleImageSearch = (url) => {

    { url && window.open("https://lens.google.com/uploadbyurl?url="+url) };
};

export const openGoogleMapLocation = (location) => {

  if (window.cordova) {
    if (window.cordova.platformId === "android") {
      { location &&  window.open('geo:0,0?q=' + location.coordinates[1] + "," + location.coordinates[0], '_system')};
    } else {
      { location && window.cordova.InAppBrowser.open('maps://?q='+location.coordinates[1]+","+location.coordinates[0], '_system')};
    }
  } else {
    { location && window.open("https://www.google.com/maps/place/?q="+location.coordinates[1]+","+location.coordinates[0])};
  }
};

export const openGoogleMapPlaceId = (placeid, location) => {

  if (window.cordova) {
    if (window.cordova.platformId === "android") {
      { location && window.open('geo:0,0?q=' + location.coordinates[1] + "," + location.coordinates[0], '_system')};
    } else {
      { location && window.cordova.InAppBrowser.open('maps://?q=' +location.coordinates[1]+","+location.coordinates[0], '_system')};
    }
  } else {
    window.open("https://www.google.com/maps/place/?q=place_id:" + placeid )
  }

};

export const openInstagram = (name) => {

  if (window.cordova) {
    if (window.cordova.platformId === "android") {
      //{ name && window.open("https://www.instagram.com/"+name,  '_system')}; - does not work
      //{ name && window.open("instagram://user?username=" + name, '_system')}; - does not work
      { name && window.cordova.InAppBrowser.open('instagram://user?username='+name, '_system')};
    } else if (window.cordova.platformId === "ios")  {
      { name && window.cordova.InAppBrowser.open("instagram://user?username=" + name, '_system')};
    } else  {
      { name && window.open("https://www.instagram.com/" + name, '_system')};
    }
  } else {
    { name && window.open("https://www.instagram.com/"+name, '_system')};
  }
};


export const searchInstagram = (name) => {

  if (window.cordova) {
    if (window.cordova.platformId === "android") {
      { name &&  window.open('instagram://user?username='+name, '_system')};
    } else if (window.cordova.platformId === "ios")  {
      { name && window.cordova.InAppBrowser.open('instagram://user?username='+name, '_system')};
    } else {
      { name && window.open('instagram://user?username='+name, '_system')};
    }
  } else {
    { name && window.open("https://www.instagram.com/explore/search/keyword/?q="+name)};
  }
};

export const searchGoogleForInstagram = (search) => {

  if (window.cordova) {
    if (window.cordova.platformId === "android") {
      { search && window.cordova.InAppBrowser.open("https://www.google.com/search?q=" + search + " site:instagram.com", '_system')};
    } else if (window.cordova.platformId === "ios")  {
      { search && window.cordova.InAppBrowser.open("https://www.google.com/search?q=" + search + " site:instagram.com", '_system')};
    } else {
      { search && window.cordova.InAppBrowser.open("https://www.google.com/search?q=" + search + " site:instagram.com", '_system')};
    }
  } else {
    { search && window.open("https://www.google.com/search?q=" + search + " site:instagram.com")};
  }
};

export const openFacebook = (name) => {

  if (window.cordova) {
    if (window.cordova.platformId === "android") {
      { name &&  window.open('fb://'+name, '_system')};
    } else {
      { name && window.cordova.InAppBrowser.open('fb://'+name, '_system')};
    }
  } else {
    { name && window.open("https://www.facebook.com/"+name)};
  }
};

export const openWhatsApp = (phone, message) => {

  if (window.cordova) {
    if (window.cordova.platformId === "android") {
      { phone && window.open("https://wa.me/" + phone + "?text=Hi")};
      //{ phone &&  window.open('fb://'+phone, '_system')};
    } else {
      { phone && window.open("https://wa.me/" + phone + "?text=Hi")};
      //{ phone && window.cordova.InAppBrowser.open('fb://'+phone, '_system')};
    }
  } else {
    { phone && window.open("https://wa.me/" + phone + "?text=Hi")};
  }
};

export const openURL = (url) => {

  if (window.cordova) {
    if (window.cordova.platformId === "android") {
      { url && window.cordova.InAppBrowser.open(url, '_system')};
    } else if (window.cordova.platformId === "ios")  {
      { url && window.cordova.InAppBrowser.open(url, '_system')};
    } else {
      { url && window.cordova.InAppBrowser.open(url, '_system')};
    }
  } else {
    { url && window.open(url, "_blank") };
  }
};

export const getTimeSlot = (timeslot: string) => {
  return (TimeSlots[timeslot] ? TimeSlots[timeslot] : "None" );
};

export const getAdminProductStatusList = () => {
  return adminProductStatus;
};

export const getAdminProductSortList = () => {
  return adminProductSort;
};

export const getSalesLeadStatus = (status: string) => {
  return (SalesLeadStatus[status] ? SalesLeadStatus[status] : "NA" );
};

export const getSalesLeadStatusList = () => {
  return SalesLeadStatus;
};

export const getSalesLeadFilterList = () => {
  return SalesLeadFilter;
};

export const getAdminSalesFutureFilterList = () => {
  return adminSalesFutureFilter;
};

export const getAdminShopFilterList = () => {
  return adminShopFilter;
};

export const getAdminShopSortList = () => {
  return adminShopSort;
};

export const getAdminOfferFilterList = () => {
  return adminOfferFilter;
};

export const getUserProductSortList = () => {
  return userProductSort;
};

export const getCategoryBreadcrum = (categories, selectedCategory) => {

  let breadcrum = selectedCategory.name;

  categories && categories.length > 0 && categories.forEach((category) => {
    category.children.forEach((child) => {
      if (child._id === selectedCategory._id) {
        breadcrum = category.name + "  >  " + selectedCategory.name;
      }
    });
  });
  return breadcrum;
}

export const getCategoryFromID = (categories, id) => {

  let categoryEntry = null;

  categories && categories.length > 0 && categories.forEach((category) => {
    category.children.forEach((child) => {
      if (child._id === id) {
        categoryEntry = child;
      }
    });
  });

  if (categoryEntry) return categoryEntry;
  else return {_id: id};
}

export const getLocationBreadcrum = (locationsmarkets, id) => {

  let breadcrum = "";

  locationsmarkets && locationsmarkets.areas && locationsmarkets.areas.length > 0 && locationsmarkets.areas.forEach((location) => {
    if (location.id === id) {
      breadcrum = " near " + location.name;
    }
  });
  return breadcrum;
}

export const getLocationMarketFromID = (locationsmarkets, id) => {

  let locationEntry = null;

  locationsmarkets && locationsmarkets.areas && locationsmarkets.areas.length > 0 && locationsmarkets.areas.forEach((location) => {
    if (location.id === id) {
      locationEntry = location;
    }

  });

  if (locationEntry) return locationEntry;
  else return {_id: id, lat: "", lng: "", name: ""};
}

export const signedNumber = (value: any) => {
  let formattedValue = "NA";
  if (typeof value !== "number") {
    try {
      formattedValue = parseFloat(value);
    } catch (e) {
      return (formattedValue = "NA");
    }
  }

  if (Math.abs(value) === 0) return 0;
  formattedValue = `${value > 0 ? "+" : "-"}${Math.abs(value).toFixed(2)}`;
  return formattedValue;
};

export const validate = {
  email: (emailId) => regEx.email.test(emailId),
  password: (password) => regEx.password.test(password),
};

export const getFileExtension = (fileName) => {
  return fileName
    .slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1)
    .toLowerCase();
};

export const isMarketPlaceOnly = () => {
  return (process.env.REACT_APP_TYPE === "MARKETPLACE");
};

// for checking shops to be displayed in marketplace or localmarket
export const isMarketPlace = () => {
  return ((process.env.REACT_APP_TYPE === "MARKETPLACE" || process.env.REACT_APP_TYPE === "LOCALMARKET"));
};

export const isLocalMarket = () => {
  return (process.env.REACT_APP_TYPE === "LOCALMARKET");
};
export const isShopSiteAndSubdomain = () => {
  return (!(process.env.REACT_APP_TYPE === "MARKETPLACE" || process.env.REACT_APP_TYPE === "LOCALMARKET"));
};

export const checkUserAllowed = (userlevel, section) => {

  if (userlevel === "L01") {
    // standee distribution
    switch (section) {
      case "shops":
      case "shopsnearby":
      case "displaydelivered":
        return true;
    }
  } else if (userlevel === "L05") {
    // Add offers
    switch (section) {
      case "shops":
      case "shopsnearby":
      case "shopaddoffers":
      case "sales":
      case "salesfutures":
      case "salesfuturesdetail":
        return true;
    }
  } else if (userlevel === "L1") {
    // Sales executives
    switch (section) {
      case "shops":
      case "shopsrecent":
      case "shopsmysignup":
      case "shopsnearby":
      case "shopaddoffers":
        //case "products":
      case "verifyshop":
      case "sales":
      case "salesnearby":
      case "salesmyleads":
      case "salesfutures":
      case "salesfuturesdetail":
      case "displaydelivered":
      case "displayreq":
      case "subscriptions":
      case "subscriptionsnearby":
        return true;
    }
  } else if (userlevel === "L2") {
    // Senior Sales Executive
    switch (section) {
      case "shops":
      case "shopsrecent":
      case "shopsmysignup":
      case "shopsnearby":
      case "shopaddoffers":
        //case "products":
      case "verifyshop":
      case "sales":
      case "salesnearby":
      case "salesall":
      case "salesmyleads":
      case "salesfutures":
      case "salesfuturesdetail":
      case "displaydelivered":
      case "displayreq":
      case "subscriptions":
      case "subscriptionsnearby":
        return true;
    }
  } else if (userlevel === "L25") {
    // Marketing team
    switch (section) {
      case "shops":
      case "shopsrecent":
      case "shopsmysignup":
      case "shopsnearby":
      case "shopaddoffers":
      case "verifyshop":
      case "searchsocial":
      case "sales":
      case "salesnearby":
      case "salesall":
      case "salesmyleads":
      case "salesfutures":
      case "salesfuturesdetail":
      case "displaydelivered":
      case "displayreq":
        return true;
    }
  } else if (userlevel === "L3") {
    // Marketplace Data team
    switch (section) {
      case "products":
      case "categories":
      case "approveproduct":
      case "disableproduct":
      case "deleteproduct":
      case "sendproductmsg":
      case "offers":
      case "searchsocial":
      case "shopaddoffers":
      case "approveoffer":
      case "disableoffer":
      case "displayreq":
      case "displayprinted":
      case "orders":
      case "shops":
      case "shopsrecent":
      case "shopsnearby":
      case "approveshop":
      case "rejectshop":
      case "editshop":
      case "enableshop":
      case "disableshop":
      case "unlockurl":
      case "scancard":
      case "subscriptions":
      case "newsubscriptions":
      case "pendingpayment":
      case "subscriptionsnearby":

        return true;
    }
  } else if (userlevel === "L4") {
    // Senior Marketplace Data Team Executive
    switch (section) {
      case "products":
      case "categories":
      case "approveproduct":
      case "disableproduct":
      case "deleteproduct":
      case "sendproductmsg":
      case "offers":
      case "searchsocial":
      case "updatesocial":
      case "updatedomain":
      case "approveoffer":
      case "disableoffer":
      case "displayreq":
      case "displaydelivered": // remove later
      case "displayprinted":
      case "orders":
      case "shops":
      case "shopsrecent":
      case "shopsmysignup":
      case "shopsnearby":
      case "shopaddoffers":
      case "approveshop":
      case "rejectshop":
      case "deleteshop":
      case "editshop":
      case "enableshop":
      case "disableshop":
      case "unlockurl":
      case "sales":
      case "salesnearby":
      case "salesmyleads":
      case "salesfutures":
      case "salesfuturesdetail":
      case "salesall":
      case "salesops":
      case "scancard":
      case "subscriptions":
      case "newsubscriptions":
      case "pendingpayment":
      case "subscriptionsnearby":
        return true;
    }
  }
  return false;
}

export const getURI = (app, section, shopId, prodId, orderId) => {

  switch (app) {
    case "USERSITE":
      switch (section) {
        case "home":
          return "/";
        case "products":
          return "/products";
        case "shop_product":
          return `/products/${prodId}`;
        case "shop_orders":
          return `/me/orders`;
        case "shop_order":
          return `/me/orders/${orderId}`;
        case "orders":
          return "/orders";
        case "order":
          return `/orders/${orderId}`;
        case "cart":
          return "/cart";
        case "account":
          return "/me";
      }
      break;
    case "SELLERAPP":
      switch (section) {
        case "home":
          return "/";
        case "shop":
          return "/shop";
        case "products":
          return "/products/private";
        case "orders":
          return "/orders";
        case "account":
          return "/me";
      }
      break;
    case "ADMINAPP":
      switch (section) {
        case "home":
          return "/";
        case "shops":
          return "/shops";
        case "shop":
          return `/shops/${shopId}`;
        case "products":
          return "/products";
        case "product":
          return `/products/${prodId}`;
        case "orders":
          return "/orders";
        case "order":
          return `/orders/${orderId}`;
        case "sales":
          return "/sales";
        case "subscriptions":
          return "/subscriptions";
        case "account":
          return "/me";
      }
      break;

    case "MARKETPLACE":
    case "LOCALMARKET":
    default:
      switch (section) {
        case "home":
          return "/";
        case "shops":
          return "/shops";
        case "products":
          return "/products";
        case "offers":
          return "/offers";
        case "offer":
          return `/offers/${orderId}`;
        case "orders":
          return "/me/orders";
        case "order":
          return `/me/orders/${orderId}`;
        case "cart":
          return "/cart";
        case "favs":
          return "/favs";
        case "account":
          return "/me";

        case "shop_home":
          return `/shop/${shopId}`;
        case "shop_products":
          return `/shop/${shopId}/products`;
        case "shop_product":
          return `/shop/${shopId}/products/${prodId}`;
        case "shop_orders":
          return `/shop/${shopId}/me/orders`;
        case "shop_order":
          return `/shop/${shopId}/me/orders/${orderId}`;
        case "shop_cart":
          return `/shop/${shopId}/cart`;
        case "shop_account":
          return `/shop/${shopId}/me`;
      }
      break;
  }
}

export const isFeatureSupported = (section) => {

  if (isAndroidApp()) {
    switch (section) {
      case "shopreels":
        return true;
    }
  } else if (isIOSApp()) {
    switch (section) {
      case "shopreels":
        return true;
    }
  } else if (!isMobileApp()) {
    switch (section) {
      case "shopreels":
        return true;
    }
  }
  return false;
}

export const checkProductInCart = (cartList, product_id) => {
  if (cartList.find(cartItem => cartItem.product.id === product_id)) {
    return true;
  }
  return false;
}

export const checkPackageInCart = (cartList, plan_id) => {
  if (cartList.find(cartItem => cartItem.plan._id === plan_id)) {
    return true;
  }
  return false;
}

export const copyToClipBoard = async copyMe => {
  try {
    await navigator.clipboard.writeText(copyMe);
  } catch (err) {
  }
};

export const parseTextWithLinks = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Split the text into parts using the regex
  const parts = text.split(urlRegex);

  // Map each part and wrap URLs in <a> tags
  return parts.map((part, index) => {
    if (urlRegex.test(part)) {
      return (
          <div>
            <Box width="100%" >
              <ButtonComponent
                  type="primary"
                  variant="contained"
                  name="Download"
                  size="medium"
                  onClick={() => openURL(part)}>
              </ButtonComponent>
            </Box>
            <Box width="100%" >
                {part}
            </Box>
            <Box width="100%" >
              <ButtonComponent
                  variant="outlined"
                  type="primary"
                  name="Copy"
                  size="small"
                  onClick={() => copyToClipBoard(part)}
              />
            </Box>
          </div>
      );
    }
    return part;
  });
}
