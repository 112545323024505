export const BreakPoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export const TimeSlots = {
  "10to12" : "10:00AM to 12:00PM",
  "12to2" : "12:00PM to 2:00PM",
  "2to4" : "2:00PM to 4:00PM",
  "4to6" : "4:00PM to 6:00PM",
  "6to8" : "6:00PM to 8:00PM",
};

export const SalesLeadStatus = [
  { label: "Owner Not Available, Visit Again", value: "tryagain" },
  { label: "Owner Interested, Visit Again", value: "visit" },
  { label: "Maybe Later", value: "later" },
  { label: "Not Interested", value: "no" },
  { label: "Signed", value: "signed" },
];

export const SalesLeadFilter = [
  { label: "All", value: "all" },
  { label: "No PlaceID", value: "noplaceid" },
  { label: "Unverified PlaceID", value: "unverifiedplaceid" },
];

export const adminSalesFutureFilter = [
  { label: "Without Lead", value: "withoutlead" },
  { label: "With Lead - no signup", value: "withleadonly" },
  { label: "Signed", value: "signed" },
  { label: "Not Updated", value: "notupdated" },
  { label: "Multi Lead", value: "multilead" },
];

export const adminProductStatus = [
  { label: "Admin - Not Verified", value: "notverified" },
  { label: "Admin - Verified", value: "verified" },
  { label: "Listing Ready", value: "ready" },
  { label: "Listing Not Ready", value: "notready" },
  { label: "Deleted", value: "deleted" },
  { label: "With Video", value: "withvideo" },
  { label: "Category - No Category", value: "nocategory" },
  { label: "Category - With Category", value: "withcategory" },
  { label: "Category - Locked", value: "categorylocked" },
  { label: "Category - Unlocked", value: "categoryunlocked" },
  { label: "Color - Locked", value: "colorlocked" },
  { label: "Color - Unlocked", value: "colorunlocked" },
  { label: "Color - With Auto Color", value: "withautocolor" },
  { label: "Color - No Auto Color", value: "noautocolor" },
];

export const adminProductSort = [
  { label: "Newest", value: "newest" },
  { label: "Oldest", value: "oldest" },
  { label: "Ranking", value: "productrank" },
];

export const userProductSort = [
  { label: "Popular", value: "popular" },
  { label: "Newest", value: "newest" },
  { label: "Oldest", value: "oldest" },
];

export const adminShopFilter = [
  { label: "All", value: "all" },
  { label: "Standee Delivery", value: "standeeindelivery" },
  { label: "No Lead", value: "nolead" },
  { label: "Verified", value: "verified" },
  { label: "Not-Verified", value: "notverified" },
  { label: "Approved", value: "approved" },
  { label: "Approved but inactive", value: "approvedinactive" },
  { label: "Not-Approved", value: "notapproved" },
  { label: "Rejected", value: "rejected" },
  { label: "Not-Rejected", value: "notrejected" },
  { label: "Not-Processed", value: "notprocessed" },
  { label: "No Logo - Active", value: "nologo" },
  { label: "Social Page", value: "socialpage" },
  { label: "No Social Page", value: "nosocialpage" },

];

export const adminShopSort = [
  { label: "Ranking", value: "shoprank" },
  { label: "No of products", value: "productcount" },
  { label: "Newest", value: "newest" },
  { label: "Oldest", value: "oldest" },
  { label: "Name", value: "name" },
];

export const adminOfferFilter = [
  { label: "All", value: "all" },
  { label: "Offer Active", value: "offeractive" },
  { label: "Offer Inactive", value: "offerinactive" },
  { label: "Shop Active", value: "shopactive" },
  { label: "Shop Inactive", value: "shopinactive" },
];

export const CURRENCY = "\u20B9"; // \u0024 - dollar, \u20B9 - Rupee, \u20AC - Euro
export const DATE_FORMAT = "MMMM Do YYYY";
export const DATE_TIME_FORMAT = "MMMM Do YYYY h:mm a";
export const DATE_TIME_FORMAT_SHORT = "DD-MMM-YY h:mma";
export const DATE_FORMAT_SHORT = "DD-MMM-YY";
export const DATE_TIME_FORMAT_WITH_SEC = "MMMM Do YYYY h:mm:s a";
