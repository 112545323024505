// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "../../api";

import * as ActionTypes from "constants/ActionTypes";
import * as Actions from "actions";
import {
  checkIfCityChanging,
  getGeoLocation,
  getGeoLocationDue, getOnLoadCityCode, getOnLoadSavedCity, getSelectedCity,
  isLocationInSupportedCity, isOnLoadCitySaved, setCity, setLoadCitySaved,
  updateLastCheckTime,
  updateUserLocation
} from "../../helpers/utils";

const querystring = require('querystring');

function* userMainGetCommonProducts(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          if (checkIfCityChanging(currentCity)) {
            yield put(Actions.getCityLocations(currentCity.id));
          }
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }

      } else {

        if (getOnLoadCityCode()) {
          if (!isOnLoadCitySaved()) {
            var selectedCity = getOnLoadSavedCity();
            if (selectedCity) {
              setLoadCitySaved();
              if (checkIfCityChanging(selectedCity)) {
                setCity(selectedCity.id);
                yield put(Actions.getCityLocations(selectedCity.id));
              }
            }
          }
        }
        coordinates = getGeoLocation();

      }
    }
  } catch (error) {
    // Reset time if running into error - check again after some time
    updateLastCheckTime();
    // get earlier or default location
    coordinates = getGeoLocation();
  }

  try {


    const searchQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      shop: action.shopId,
      isDomain: action.isShopDomain ? "1" : "",
      category: action.category
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
      `/users/products/get-products` + query
    );
    yield put(Actions.userMainGetCommonProductsSuccess(response));
  } catch (error) {
    yield put(Actions.userMainGetCommonProductsFailure(error));
  }
}

function* userMainGetPopularProducts(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          if (checkIfCityChanging(currentCity)) {
            yield put(Actions.getCityLocations(currentCity.id));
          }
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }
      } else {

        if (getOnLoadCityCode()) {
          if (!isOnLoadCitySaved()) {
            var selectedCity = getOnLoadSavedCity();
            if (selectedCity) {
              setLoadCitySaved();
              if (checkIfCityChanging(selectedCity)) {
                setCity(selectedCity.id);
                yield put(Actions.getCityLocations(selectedCity.id));
              }
            }
          }
        }
        coordinates = getGeoLocation();

      }
    }
  } catch (error) {
    // Reset time if running into error - check again after some time
    updateLastCheckTime();
    // get earlier or default location
    coordinates = getGeoLocation();
  }


  try {

    const searchQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      shop: action.shopId,
      isDomain: action.isShopDomain ? "1" : "",
      sort: "popular",
      category: "",
      color: "",
      location: "",
      market: action.market,
      name: "",
      offset: "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/products/get-products`+ query
    );
    yield put(Actions.userMainGetPopularProductsSuccess(response));
  } catch (error) {
    yield put(Actions.userMainGetPopularProductsFailure(error));
  }
}

function* userMainGetNewProducts(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          if (checkIfCityChanging(currentCity)) {
            yield put(Actions.getCityLocations(currentCity.id));
          }
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }
      } else {

        if (getOnLoadCityCode()) {
          if (!isOnLoadCitySaved()) {
            var selectedCity = getOnLoadSavedCity();
            if (selectedCity) {
              setLoadCitySaved();
              if (checkIfCityChanging(selectedCity)) {
                setCity(selectedCity.id);
                yield put(Actions.getCityLocations(selectedCity.id));
              }
            }
          }
        }
        coordinates = getGeoLocation();

      }
    }
  } catch (error) {
    // Reset time if running into error - check again after some time
    updateLastCheckTime();
    // get earlier or default location
    coordinates = getGeoLocation();
  }


  try {

    const searchQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      shop: action.shopId,
      isDomain: action.isShopDomain ? "1" : "",
      sort: "new",
      category: "",
      color: "",
      location: "",
      market: action.market,
      name: "",
      offset: "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/products/get-products`+ query
    );
    yield put(Actions.userMainGetNewProductsSuccess(response));
  } catch (error) {
    yield put(Actions.userMainGetNewProductsFailure(error));
  }
}

function* userMainGetProductCategories(action: Object) {
  try {

    const searchQuery = querystring.stringify({ shop: action.shopId, isDomain: action.isShopDomain ? "1" : "" });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/categories/get-categories`+ query
    );
    yield put(Actions.userMainGetProductCategoriesSuccess(response));
  } catch (error) {
    yield put(Actions.userMainGetProductCategoriesFailure(error));
  }
}

function* userMainGetProductPopularCategories(action: Object) {
  try {

    const searchQuery = querystring.stringify({ shop: action.shopId, isDomain: action.isShopDomain ? "1" : "", popular: "1" });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/categories/get-categories`+ query
    );
    yield put(Actions.userMainGetProductPopularCategoriesSuccess(response));
  } catch (error) {
    yield put(Actions.userMainGetProductPopularCategoriesFailure(error));
  }
}

function* userMainSearchProducts(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          if (checkIfCityChanging(currentCity)) {
            yield put(Actions.getCityLocations(currentCity.id));
          }
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }
      } else {

        if (getOnLoadCityCode()) {
          if (!isOnLoadCitySaved()) {
            var selectedCity = getOnLoadSavedCity();
            if (selectedCity) {
              setLoadCitySaved();
              if (checkIfCityChanging(selectedCity)) {
                setCity(selectedCity.id);
                yield put(Actions.getCityLocations(selectedCity.id));
              }
            }
          }
        }
        coordinates = getGeoLocation();

      }
    }
  } catch (error) {
    // Reset time if running into error - check again after some time
    updateLastCheckTime();
    // get earlier or default location
    coordinates = getGeoLocation();
  }

  try {

    const searchQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      shop: action.shopId,
      isDomain: action.isShopDomain ? "1" : "",
      sort: action.sort,
      category: action.category,
      color: action.color,
      location: action.location,
      market: action.market,
      name: action.keyword,
      offset: action.offset ?  action.offset : "",
    });
    // Any change above must also be applied to userMainGetPopularProducts, userMainGetNewProducts
    // URI sequence of params MUST remain consistent with Popular, New
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/products/get-products`+ query
    );
    yield put(Actions.userMainSearchProductsSuccess(response));
  } catch (error) {
    yield put(Actions.userMainSearchProductsFailure(error));
  }
}

function* userMainSearchKeywords(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          if (checkIfCityChanging(currentCity)) {
            yield put(Actions.getCityLocations(currentCity.id));
          }
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }
      } else {

        if (getOnLoadCityCode()) {
          if (!isOnLoadCitySaved()) {
            var selectedCity = getOnLoadSavedCity();
            if (selectedCity) {
              setLoadCitySaved();
              if (checkIfCityChanging(selectedCity)) {
                setCity(selectedCity.id);
                yield put(Actions.getCityLocations(selectedCity.id));
              }
            }
          }
        }
        coordinates = getGeoLocation();

      }
    }
  } catch (error) {
    // Reset time if running into error - check again after some time
    updateLastCheckTime();
    // get earlier or default location
    coordinates = getGeoLocation();
  }

  try {

    const searchKeywordQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      name: action.keyword });

    const query = (searchKeywordQuery) ? "?"+searchKeywordQuery : "" ;

    const searchQuery = (action.isShop) ?
        ('/users/products/seller/get-keywords?name=' + action.keyword + '&shop=' + action.shopId + ((action.isShopDomain) ? "&isDomain=1" : "")) :
        ('/users/products/get-keywords' + query);

    const response = yield API.get(searchQuery);
    yield put(Actions.userMainSearchKeywordsSuccess(response));
  } catch (error) {
    yield put(Actions.userMainSearchKeywordsFailure(error));
  }
}

function* userMainSearchCombined(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          if (checkIfCityChanging(currentCity)) {
            yield put(Actions.getCityLocations(currentCity.id));
          }
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }
      } else {

        if (getOnLoadCityCode()) {
          if (!isOnLoadCitySaved()) {
            var selectedCity = getOnLoadSavedCity();
            if (selectedCity) {
              setLoadCitySaved();
              if (checkIfCityChanging(selectedCity)) {
                setCity(selectedCity.id);
                yield put(Actions.getCityLocations(selectedCity.id));
              }
            }
          }
        }
        coordinates = getGeoLocation();

      }
    }
  } catch (error) {
    // Reset time if running into error - check again after some time
    updateLastCheckTime();
    // get earlier or default location
    coordinates = getGeoLocation();
  }

  try {

    const searchQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      market: action.market,
      //isDomain: action.isShopDomain ? "1" : "",
      name: action.keyword });

    //const searchQuery = querystring.stringify({ shop: action.shopId, isDomain: action.isShopDomain ? "1" : "", name: action.keyword });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/products/get-combined`+ query
    );
    yield put(Actions.userMainSearchCombinedSuccess(response));
  } catch (error) {
    yield put(Actions.userMainSearchCombinedFailure(error));
  }
}

function* userMainSearchKeywordsCombined(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        var currentCity = isLocationInSupportedCity(response.coords);

        if (currentCity) {
          if (checkIfCityChanging(currentCity)) {
            yield put(Actions.getCityLocations(currentCity.id));
          }
          updateUserLocation(response.coords, currentCity);
          coordinates = {lat: latitude, lng: longitude};
        } else {
          updateLastCheckTime();
          coordinates = getGeoLocation();
        }
      } else {

        if (getOnLoadCityCode()) {
          if (!isOnLoadCitySaved()) {
            var selectedCity = getOnLoadSavedCity();
            if (selectedCity) {
              setLoadCitySaved();
              if (checkIfCityChanging(selectedCity)) {
                setCity(selectedCity.id);
                yield put(Actions.getCityLocations(selectedCity.id));
              }
            }
          }
        }
        coordinates = getGeoLocation();

      }
    }
  } catch (error) {
    // Reset time if running into error - check again after some time
    updateLastCheckTime();
    // get earlier or default location
    coordinates = getGeoLocation();
  }

  try {

    const searchKeywordQuery = querystring.stringify({
      lat: coordinates.lat ? coordinates.lat : "",
      lng: coordinates.lng ? coordinates.lng : "",
      sort: action.sort ? action.sort : "nearby",
      name: action.keyword });

    const query = (searchKeywordQuery) ? "?"+searchKeywordQuery : "" ;

    const searchQuery = (action.isShop) ?
        ('/users/products/seller/get-keywords?name=' + action.keyword + '&shop=' + action.shopId + ((action.isShopDomain) ? "&isDomain=1" : "")) :
        ('/users/products/get-combined-keywords' + query);

    const response = yield API.get('/users/products/get-combined-keywords' + query);
    yield put(Actions.userMainSearchKeywordsCombinedSuccess(response));
  } catch (error) {
    yield put(Actions.userMainSearchKeywordsCombinedFailure(error));
  }
}

function* userMainGetProductInfo(action: Object) {
  try {
    const response = yield API.get(
        `/users/products/get-product-shops/` + action.payload
    );
    yield put(Actions.userMainGetProductInfoSuccess(response));
  } catch (error) {
    yield put(Actions.userMainGetProductInfoFailure(error));
  }
}

function* userMainGetProductPrices(action: Object) {

  try {

    let query = {};

//    if ('geolocation' in window) {
      const usergetUserGeoLocation = () =>
          new Promise((resolve, reject) => {
            var options = {
              timeout: 5000,
              maximumAge: 0,
              enableHighAccuracy: true
            };
            navigator.geolocation.getCurrentPosition(
                (location) => resolve(location),
                (error) => reject(error),
                options
            );
          });

      const response = yield call(usergetUserGeoLocation);
      const {latitude, longitude} = response.coords;

       query = {
        lat: latitude,
        lng: longitude,
      };
//    }

    const prices = yield API.get(
        `/users/products/seller/get-prices/` + action.payload + ((action.isProd) ? "?isProd=1" : ""),
        { params: query }
    );
    yield put(Actions.userMainGetProductPricesSuccess(prices));
  } catch (error) {
    yield put(Actions.userMainGetProductPricesFailure(error));
  }
}


export default function* ProductsSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.USER_MAIN_GET_COMMON_PRODUCTS_START,       userMainGetCommonProducts);
  yield takeLatest(ActionTypes.USER_MAIN_GET_POPULAR_PRODUCTS_START,      userMainGetPopularProducts);
  yield takeLatest(ActionTypes.USER_MAIN_GET_NEW_PRODUCTS_START,          userMainGetNewProducts);
  yield takeLatest(ActionTypes.USER_MAIN_GET_ACTIVE_CATEGORIES_START,     userMainGetProductCategories);
  yield takeLatest(ActionTypes.USER_MAIN_GET_POPULAR_ACTIVE_CATEGORIES_START, userMainGetProductPopularCategories);
  yield takeLatest(ActionTypes.USER_MAIN_SEARCH_PRODUCTS_START,           userMainSearchProducts);
  yield takeLatest(ActionTypes.USER_MAIN_SEARCH_KEYWORDS_START,           userMainSearchKeywords);
  yield takeLatest(ActionTypes.USER_MAIN_SEARCH_COMBINED_START,           userMainSearchCombined);
  yield takeLatest(ActionTypes.USER_MAIN_SEARCH_KEYWORDS_COMBINED_START,  userMainSearchKeywordsCombined);
  yield takeLatest(ActionTypes.USER_MAIN_GET_PRODUCT_INFO_START,          userMainGetProductInfo);
  yield takeLatest(ActionTypes.USER_MAIN_GET_PRODUCT_PRICES_UPC_START,    userMainGetProductPrices);
}
