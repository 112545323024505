// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions/admin/admin.products";
import {adminGetProductAndShopsInfoFailure, adminGetProductAndShopsInfoSuccess} from "actions/admin/admin.products";

const querystring = require('querystring');

function* adminGetCommonProducts(action: Object) {
  try {
    const response = yield API.get(
      `/admin/products/search-products` + (action.category ? `?category=` + action.category : ""),
      action.payload
    );
    yield put(Actions.adminGetCommonProductsSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetCommonProductsFailure(error));
  }
}

function* adminGetProductInfo(action: Object) {
  try {
    const response = yield API.get(
        `/admin/products/get-product/` + action.payload
    );
    yield put(Actions.adminGetProductInfoSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetProductInfoFailure(error));
  }
}

function* adminGetProductAndShopsInfo(action: Object) {
  try {
    const response = yield API.get(
        `/admin/products/get-product-shops/` + action.payload
    );
    yield put(Actions.adminGetProductAndShopsInfoSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetProductAndShopsInfoFailure(error));
  }
}

function* adminUpdateProductStatus(action: Object) {
  try {
    const response = yield API.post(
        `admin/products/update-status`,
        action.payload
    );
    yield put(Actions.adminUpdateProductStatusSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateProductStatusFailure(error));
  }
}

function* adminSearchCommonCategory(action: Object) {
  try {
    const searchQuery = querystring.stringify({
      name: action.name ? action.name : "",
    });
    const query = (searchQuery) ? "?"+searchQuery : "" ;
    const response = yield API.get(
        `/admin/categories/search-keywords` + query
    );
    yield put(Actions.adminSearchCommonCategorySuccess(response));
  } catch (error) {
    yield put(Actions.adminSearchCommonCategoryFailure(error));
  }
}

function* adminGetCommonProductCategories(action: Object) {
  try {
    const response = yield API.get(
        `/admin/categories/search-categories`,
        action.payload
    );
    yield put(Actions.adminGetCommonProductCategoriesSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetCommonProductCategoriesFailure(error));
  }
}

function* adminGetCommonProductMainCategories(action: Object) {
  try {
    const response = yield API.get(
        `/admin/categories/search-categories?main=1`,
        action.payload
    );
    yield put(Actions.adminGetCommonProductMainCategoriesSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetCommonProductMainCategoriesFailure(error));
  }
}

function* adminGetCommonProductColors(action: Object) {
  try {
    const response = yield API.get(
        `/admin/categories/search-colors`,
        action.payload
    );
    yield put(Actions.adminGetCommonProductColorsSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetCommonProductColorsFailure(error));
  }
}

function* adminUpdateProductInfo(action: Object) {
  try {

    const response = yield API.post(
        `admin/products/update-info`,
        action.payload
    );
    yield put(Actions.adminUpdateProductInfoSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateProductInfoFailure(error));
  }
}

function* adminUpdateProductCategory(action: Object) {
  try {

    const response = yield API.post(
        `admin/products/update-category`,
        action.payload
    );
    yield put(Actions.adminUpdateProductCategorySuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateProductCategoryFailure(error));
  }
}

function* adminGetStorePrivateProducts(action: Object) {
  try {

    const searchQuery = querystring.stringify({ id: action.shopId, private: action.private === true ? "1" : "" });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/admin/products/seller-products-common` + query,
        action.payload
    );
    yield put(Actions.adminGetStorePrivateProductsSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetStorePrivateProductsFailure(error));
  }
}

function* adminGetStorePrivateCategories(action: Object) {
  try {
    const searchQuery = querystring.stringify({ id: action.shopId, private: action.private === true ? "1" : "" });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/admin/categories/seller-categories` + query
    );
    yield put(Actions.adminGetStorePrivateCategoriesSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetStorePrivateCategoriesFailure(error));
  }
}

function* adminUpdateStatusPrivateProduct(action: Object) {
  try {
    const response = yield API.post(
        `admin/products/update-status`,
        action.payload,
    );
    yield put(Actions.adminUpdateStatusPrivateProductSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateStatusPrivateProductFailure(error));
  }
}

function* adminSearchProducts(action: Object) {
  try {
    const searchQuery = querystring.stringify({
      name: action.name ? action.name : "",
      shopId: action.shopId ? action.shopId : "",
      isShop: action.isShop ? action.isShop : "",
      status: action.status ? action.status : "",
      start: action.start ? action.start : "",
      sort: action.sort ? action.sort : "",
      category: action.category ? action.category : "",
      offset: action.offset ? action.offset : "",
    });

    let query = (searchQuery) ? "?" + searchQuery : "" ;

    query = query + (action.city && "&lat=" + action.city.lat + "&lng=" + action.city.lng);

    const response = yield API.get(
      `/admin/products/get-products` + query
    );
    yield put(Actions.adminSearchProductsSuccess(response));
  } catch (error) {
    yield put(Actions.adminSearchProductsFailure(error));
  }
}
function* adminSearchProductKeywords(action: Object) {
  try {
    const searchQuery = querystring.stringify({
      name: action.name ? action.name : "",
      shopId: action.shopId ? action.shopId : "",
      isShop: action.isShop ? action.isShop : "",
    });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/admin/products/get-keywords` + query
    );
    yield put(Actions.adminSearchProductKeywordsSuccess(response));
  } catch (error) {
    yield put(Actions.adminSearchProductKeywordsFailure(error));
  }
}

function* adminAddPrivateProductQuick(action: Object) {
  try {
    const response = yield API.post(
        `/admin/products/add-private-quick/` +  action.payload,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );

    yield put(Actions.adminAddPrivateProductQuickSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddPrivateProductQuickFailure(error));
  }
}


function* adminUpdateProductVideo(action: Object) {
  try {
    const response = yield API.post(
        `admin/products/update-video/` + action.payload,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );
    yield put(Actions.adminUpdateProductVideoSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateProductVideoFailure(error));
  }
}

function* adminAddCommonProduct(action: Object) {
  try {
    const response = yield API.post(
        `admin/products/add-common-private`,
        action.payload
    );
    yield put(Actions.adminAddCommonProductSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddCommonProductFailure(error));
  }
}

function* adminUpdateCommonProduct(action: Object) {
  try {
    const response = yield API.post(
        `sellers/products/update-product-private/` + action.payload.prod_id,
        action.payload
    );
    yield put(Actions.adminUpdateCommonProductSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateCommonProductFailure(error));
  }
}

function* adminAddCommonProductImage(action: Object) {
  try {
    const response = yield API.post(
        `admin/products/add-images/` + action.payload,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );
    yield put(Actions.adminAddCommonProductImageSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddCommonProductImageFailure(error));
  }
}

function* adminRemoveCommonProductImage(action: Object) {
  try {
    const response = yield API.post(
        `/admin/products/remove-image`,
        action.payload
    );
    yield put(Actions.adminRemoveCommonProductImageSuccess(response));
  } catch (error) {
    yield put(Actions.adminRemoveCommonProductImageFailure(error));
  }
}

function* adminAddCommonCategory(action: Object) {
  try {
    const response = yield API.post(
        `admin/categories/add-common-category`,
        action.payload
    );
    yield put(Actions.adminAddCommonCategorySuccess(response));
  } catch (error) {
    yield put(Actions.adminAddCommonCategoryFailure(error));
  }
}

function* adminUpdateCommonCategory(action: Object) {
  try {
    const response = yield API.post(
        `admin/categories/update-category/` + action.payload.id,
        action.payload
    );
    yield put(Actions.adminUpdateCommonCategorySuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateCommonCategoryFailure(error));
  }
}

function* adminUpdateCommonCategorySetting(action: Object) {
  try {
    const response = yield API.post(
        `admin/categories/update-category-setting/` + action.payload.id,
        action.payload
    );
    yield put(Actions.adminUpdateCommonCategorySettingSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateCommonCategorySettingFailure(error));
  }
}

function* adminAddCommonCategoryImage(action: Object) {
  try {
    const response = yield API.post(
        `admin/categories/update-category-image/` + action.payload,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );
    yield put(Actions.adminAddCommonCategoryImageSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddCommonCategoryImageFailure(error));
  }
}

function* adminRemoveCommonCategoryImage(action: Object) {
  try {
    const response = yield API.post(
        `/admin/categories/remove-image`,
        action.payload
    );
    yield put(Actions.adminRemoveCommonCategoryImageSuccess(response));
  } catch (error) {
    yield put(Actions.adminRemoveCommonCategoryImageFailure(error));
  }
}

export default function* ProductsSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.ADMIN_SEARCH_PRODUCTS_START, adminSearchProducts);
  yield takeLatest(ActionTypes.ADMIN_SEARCH_PRODUCTS_KEYWORDS_START, adminSearchProductKeywords);
  yield takeLatest(ActionTypes.ADMIN_GET_PRODUCT_INFO_START, adminGetProductInfo);
  yield takeLatest(ActionTypes.ADMIN_GET_PRODUCT_SHOPS_INFO_START, adminGetProductAndShopsInfo);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_PRODUCT_STATUS_START, adminUpdateProductStatus);
  yield takeLatest(ActionTypes.ADMIN_SEARCH_COMMON_CATEGORY_START, adminSearchCommonCategory);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_PRODUCT_INFO_START, adminUpdateProductInfo);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_PRODUCT_CATEGORY_START, adminUpdateProductCategory);

  yield takeLatest(ActionTypes.ADMIN_GET_STORE_PRIVATE_PRODUCTS_START, adminGetStorePrivateProducts);
  yield takeLatest(ActionTypes.ADMIN_GET_STORE_PRIVATE_CATEGORIES_START, adminGetStorePrivateCategories);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_START, adminUpdateStatusPrivateProduct);

  yield takeLatest(ActionTypes.ADMIN_GET_COMMON_PRODUCTS_START, adminGetCommonProducts);
  yield takeLatest(ActionTypes.ADMIN_GET_COMMON_CATEGORIES_START, adminGetCommonProductCategories);
  yield takeLatest(ActionTypes.ADMIN_GET_COMMON_COLORS_START, adminGetCommonProductColors);
  yield takeLatest(ActionTypes.ADMIN_GET_COMMON_MAIN_CATEGORIES_START, adminGetCommonProductMainCategories);

  yield takeLatest(ActionTypes.ADMIN_ADD_PRIVATE_PRODUCT_QUICK_START, adminAddPrivateProductQuick);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_PRODUCT_VIDEO_START, adminUpdateProductVideo);

  yield takeLatest(ActionTypes.ADMIN_ADD_COMMON_PRODUCT_START, adminAddCommonProduct);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_COMMON_PRODUCT_START, adminUpdateCommonProduct);
  yield takeLatest(ActionTypes.ADMIN_ADD_COMMON_PRODUCT_IMAGE_START, adminAddCommonProductImage);
  yield takeLatest(ActionTypes.ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_START, adminRemoveCommonProductImage);
  yield takeLatest(ActionTypes.ADMIN_ADD_COMMON_CATEGORY_START, adminAddCommonCategory);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_START, adminUpdateCommonCategory);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_SETTING_START, adminUpdateCommonCategorySetting);
  yield takeLatest(ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_START, adminAddCommonCategoryImage);
  yield takeLatest(ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_START, adminRemoveCommonCategoryImage);

}
