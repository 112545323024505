import * as React from "react";
import {
    Box, Card, Chip, Container, IconButton, InputAdornment, TextField, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";
import {getLocalDate, checkUserAllowed, getPrice} from "helpers/utils";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import {useState} from "react";

import {H2, H5} from "components/CustomTypography";
import ImageAlbum from "components/elements/main/ImageAlbum";
import { schema, getButtonData, getTextfieldData} from "./helper";
import TextFieldComponent from "components/TextField";
import CustomSectionCreator from "components/CustomSectionCreator";


const AdminSellerProductEditorSetting = ({
    setState, selectedProduct, onCancel, productdetail, productshops, runCategorySearch, optionValuesCategory, updateProductCategory, userDetails,
    history, isLoading, currency, updateProductClicked
}) => {

    const {
     _id, name, description
  } = selectedProduct;


    const [blur, setblur] = React.useState({
        name: false,
        description: false,
    });

    const onFinish = () => {
        updateProductClicked({
            _id, name, description,
        });
    };

    const handleTextFieldChange = (event, field) => {
        setState(event.target.value, field);
        setblur({ ...blur, [field]: true });
    };

    const hasError = () => {
        try {
            return !schema.validateSync(selectedProduct);
        } catch (err) {
            return true;
        }
    };

    const handleBlur = (field) => {
        setblur({ ...blur, [field]: true });
    };

    const isTouched = () => (blur.name || blur.description );

    const getError = (field) => {
        if (blur[field] && hasError()) {
            try {
                schema.validateSyncAt(field, selectedProduct);
            } catch (err) {
                return err.message;
            }
        }
        return null;
    };


    const renderProductInformation = () => (
        <Box>
            <H2 fontSize={18} sx={{ mb: "2", mt: "4", textTransform: "capitalize" }} textAlign="left" title={selectedProduct.name} ellipsis>
                {selectedProduct.name}
            </H2>
            <Box width="100%" display="flex" mb={2}>
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        <b>Description:</b>
                    </H5>
                </Box>
                <Box width="70%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        {selectedProduct.description}
                    </H5>
                </Box>
            </Box>
            <Box width="100%" display="flex" mb={2}>
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        <b>Product ID:</b>
                    </H5>
                </Box>
                <Box width="70%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        {selectedProduct.product_id}
                    </H5>
                </Box>
            </Box>
            <CustomSectionCreator isSubtle={true} title="Category"  />
            <Box width="100%" display="flex" mb={2}>
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        <b>Auto Category:</b>
                    </H5>
                </Box>
                <Box width="70%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        {selectedProduct.autocategory}
                    </H5>
                </Box>
            </Box>
            <Box width="100%" display="flex" mb={2}>
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        <b>Category ID:</b>
                    </H5>
                </Box>
                <Box width="70%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        {selectedProduct.commoncategories && selectedProduct.commoncategories[0]._id + " (" + selectedProduct.commoncategories[0].name + ")"}

                    </H5>
                </Box>
            </Box>
            <Box width="100%" display="flex" mb={2}>
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        <b>Category:</b>
                    </H5>
                </Box>
                <Box width="70%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        {selectedProduct.category} ({selectedProduct.is_category_locked ? "Locked" : "Unlocked"})
                    </H5>
                </Box>
            </Box>
            <CustomSectionCreator isSubtle={true} title="Color"  />
            <Box width="100%" display="flex" mb={2}>
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        <b>Auto Color:</b>
                    </H5>
                </Box>
                <Box width="70%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        {selectedProduct.autocolor}
                    </H5>
                </Box>
            </Box>
            <Box width="100%" display="flex" mb={2}>
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        <b>Color:</b>
                    </H5>
                </Box>
                <Box width="70%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        {selectedProduct.color} ({selectedProduct.is_color_locked ? "Locked" : "Unlocked"})
                    </H5>
                </Box>
            </Box>
            <CustomSectionCreator isSubtle={true} title="Metadata"  />
            <Box width="100%" display="flex" mb={2}>
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        <b>Metadata:</b>
                    </H5>
                </Box>
                <Box width="70%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        {selectedProduct.metadata}
                    </H5>
                </Box>
            </Box>
        </Box>
    );

    const renderProductPhotos = () => (
        <Box>
            <ImageAlbum images={selectedProduct.images} />
        </Box>
    );

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            runCategorySearch(e.target.value);
        }
    };

    const handleDeleteCategory = () => {
        updateProductCategory(productdetail.id, "");
    }


    const setCategory = (category) => {
        setState({ resetvalue2: "" });
        updateProductCategory(productdetail.id, category.value);
    };

    const renderCategorySearchHelp = () => (
        <Container>
            <CustomSectionCreator isSubtle={true} title="Update Category"  />
            <Box>
                <Autocomplete
                    id="autocomplete2"
                    //onClose={setKeyword}
                    disableClearable
                    freeSolo
                    //inputValue={resetValue2}
                    onOpen={() => setState({ selected: false })}
                    //value={resetValue2}
                    onKeyPress={
                        handleKeyPress
                    }
                    onChange={(event, newValue) => {
                        setCategory(newValue);
                    }}
                    onSelect={
                        (input) => runCategorySearch(input.target.value)
                    }
                    options={
                        optionValuesCategory
                    }
                    renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.value}>
                            {option.parent} {option.label}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton size="large">
                                            <ArrowBackIosIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            {...params}
                            type="search"
                            size="large"
                            //autoFocus={isModalSearch}
                            label={`Select Category`}
                            placeholder={`Select Category`}
                            margin="normal"
                        />
                    )}
                />
            </Box>
            <Box>
                {productdetail.commoncategories && productdetail.commoncategories.length > 0 && (
                    <Chip
                        label={productdetail.commoncategories[0].name}
                        onDelete={handleDeleteCategory}
                    />
                )}
            </Box>



        </Container>
    );

    const renderProductShops = () => (
        <Box>
            {productshops.map((shop, index) => (
                <Box onClick={() => history.push(`/shops/shop/${shop.id}`)} alignContent="center" width="100%" display="flex" p={2}>
                    <Box width="60%" order={1}>
                        <H2 fontSize={16} sx={{ mb: "2", mt: "4", textTransform: "capitalize" }} textAlign="left" title={shop.title} ellipsis>
                            {shop.title}
                        </H2>
                    </Box>
                    <Box width="20%" order={3}>
                        {getPrice(shop, currency)}
                    </Box>
                    <Box width="20%" order={4}>
                        <ButtonComponent
                            type="primary"
                            variant="contained"
                            name="View"
                            size="small"
                            onClick={() => history.push(`/shops/shop/${shop.id}`)}
                        />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    return (
        <Container>
            <Container maxWidth="md">
                <Box p={2} width="100%" display="flex">
                    <Box width="50%">
                        <ButtonComponent
                            type="primary"
                            variant="outlined"
                            name="< Back"
                            size="small"
                            onClick={onCancel}
                        />
                    </Box>
                    <Box width="50%">
                        <Typography className="styled-link" align="right" display="block" variant="subtitle2">
                            {checkUserAllowed(userDetails.userlevel, "categories") &&
                                <ButtonComponent
                                    type="primary"
                                    variant="contained"
                                    size="small"
                                    name="Manage Category"
                                    onClick={() => (history.push("/products/categories"))}
                                />
                            }
                        </Typography>
                    </Box>
                </Box>

                {renderProductInformation()}

                <CustomSectionCreator isSubtle={true} title="Update Name & Description"  />
                <Box mb={2}>
                    {getTextfieldData(name, description).map((element) => (
                        <Box className="textfield" m={2}>
                            <TextFieldComponent
                                required
                                label={element.label}
                                value={element.value}
                                error={!!getError(element.onChangeField)}
                                helperText={getError(element.onChangeField)}
                                onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                            />
                        </Box>
                    ))}

                    <Typography align="center">
                        {getButtonData(onFinish, onCancel, hasError, isTouched).map((element) => (
                            <ButtonComponent
                                sx={{
                                    m: 2,
                                }}
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                            />
                        ))}
                    </Typography>
                </Box>
                {renderCategorySearchHelp()}
                {renderProductPhotos()}
                {renderProductShops()}
            </Container>
        </Container>
    );
};

export default AdminSellerProductEditorSetting;
