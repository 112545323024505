import React, { useEffect, useState, useRef } from 'react';
import Swiper from 'swiper';
import { Mousewheel } from 'swiper/modules';
import { getIsMute, setIsMute } from "helpers/utils";

import { StoriesSlider, Stories, Story } from './stories-slider-react';
import './stories-slider.scss';

import './main.scss';

export default function StoriesPlayer(props) {

  const { storiesData, onCancel, loadIndex, setAsFav } = props;

  const [isMuted, setIsMuted] = useState(getIsMute());

  const storiesSliderRef = useRef(null);

  const openUserStories = (userIndex) => {
    const storiesSlider = storiesSliderRef.current;

    // add "in" class (used in demo for animated appearance)
    storiesSlider.el.classList.add('stories-slider-in');

    // enable slider (as we passed enabled: false initially)
    storiesSlider.enable();

    // slide to specific user's stories
    storiesSlider.slideTo(userIndex, 0);
  };

  const onCloseButtonClick = () => {
    const storiesSlider = storiesSliderRef.current;
    // disable slider as we don't need it autoplay stories while it is hidden
    storiesSlider.disable();
    // add "out" class (used in demo for animated disappearance)
    storiesSlider.el.classList.add('stories-slider-out');
    onCancel();
  };

  useEffect(() => {
    const storiesSlider = storiesSliderRef.current;
    if (storiesSlider) {
      // when slider became hidden we need to remove "in" and "out" class to return it initial state
      storiesSlider.el.addEventListener('animationend', () => {
        if (storiesSlider.el.classList.contains('stories-slider-out')) {
          storiesSlider.el.classList.remove('stories-slider-in');
          storiesSlider.el.classList.remove('stories-slider-out');
        }
      });
      openUserStories(loadIndex || 0);
    }
  }, [storiesSliderRef]);

  const toggleMute = () => {
    var mute = !isMuted;
    setIsMuted(mute);
    setIsMute(mute);
  };

  const setFav = (favId) => {
    setAsFav(favId);
  };

  return (
    <>
      {/* Stories Slider */}
      { storiesData.length > 0 && <StoriesSlider
        Swiper={Swiper}
        EffectCube={Mousewheel}
        enabled={false}
        autoplayDurationVideo={14000}
        autoplayDurationPhoto={4000}
        onSlidesIndexesChange={(mainIndex, subIndex) =>
          console.log({ mainIndex, subIndex })
        }
        onStoriesSlider={(instance) => {
          storiesSliderRef.current = instance;
        }}
        onEnd={() => {
          // disable on last story ended
          storiesSliderRef.current.disable();
          storiesSliderRef.current.el.classList.add('stories-slider-out');
          onCancel();
        }}
      >
        {storiesData.length > 0 && storiesData.map((userStories, userStoriesIndex) => (
          <Stories key={userStoriesIndex}>
            {userStories.stories.map((story, storyIndex) => (
              <Story
                key={storyIndex}
                userLink={userStories.user.userLink}
                linkTitle={userStories.user.linkTitle}
                avatar={userStories.user.avatar && <img src={userStories.user.avatar} />}
                name={userStories.user.name}
                area={userStories.user.area}
                favId={userStories.favId}
                toggleMute={toggleMute}
                isMuted={isMuted}
                setFav={setFav}
                closeButton
                onCloseButtonClick={onCloseButtonClick}
              >
                {story.video ? (
                  <video src={story.video} muted={isMuted} playsInline preload="metadata" />
                ) : (
                  <img src={story.image} />
                )}
              </Story>
            ))}
          </Stories>
        ))}
      </StoriesSlider>}
    </>
  );
}
